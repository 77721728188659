import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import CardList from "../components/CardList";
import { TextBanner } from "../components/molecules/TextBanner";
import { LayoutBox } from "../components/atoms/LayoutBox";
import { TextAndImageSection } from "../components/molecules/TextAndImageSection";

const AlfredPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout
      title={frontmatter.meta_title}
      description={frontmatter.meta_description}
      index={false}
    >
      <AlfredPageTemplate
        title={frontmatter.title}
        survivors={frontmatter.survivors}
        carers={frontmatter.carers}
        clinicians={frontmatter.clinicians}
        features={frontmatter.features}
      />
    </Layout>
  );
};

AlfredPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

const AlfredPageTemplate = ({ title, survivors, carers, features = [] }) => (
  <div>
    <TextBanner title={title} />

    <LayoutBox>
      <h1 className="subtitle has-text-weight-light is-size-6-mobile is-size-6-tablet is-size-5-widescreen pt-4">
        Alfred was our first app and is no longer available.
      </h1>
      <h1 className="subtitle has-text-weight-light is-size-6-mobile is-size-6-tablet is-size-5-widescreen pt-4">
        Our new app is called neumind, you can join it now.
      </h1>

      {features.map((item, idx) => {
        return (
          <TextAndImageSection
            key={idx}
            title={item.tagline}
            mainText={item.blurb}
            image={item.image}
            hasAltBackgroundColor={false}
            isReverseOrder={idx % 2 !== 1}
            hasPortraitImage={true}
          />
        );
      })}

      <div className="section">
        <div className="columns">
          <div className="column is-10 is-offset-1 has-text-centered">
            <div className="content">
              <h1 className="title is-2">
                Alfred is for family members, friends, carers, and healthcare
                professionals too
              </h1>
            </div>
          </div>
        </div>
      </div>

      <CardList
        title={survivors.tagline}
        items={survivors.items}
        id="Survivors"
      />
      <CardList title={carers.tagline} items={carers.items} id="Carers" />
    </LayoutBox>
  </div>
);

AlfredPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  survivors: PropTypes.object,
  carers: PropTypes.object,
  clinicians: PropTypes.object,
  features: PropTypes.array,
};

export default AlfredPage;

export const alfredPageQuery = graphql`
  query AlfredPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        features {
          tagline
          blurb
          image {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
        survivors {
          tagline
          items {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            text
          }
        }
        carers {
          tagline
          items {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            text
          }
        }
      }
    }
  }
`;
