import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const CardList = ({ title, items, id }) => (
  <div id={id} className="section">
    <div className="content">
      <div className="block">
        <h1 className="title has-text-centered">{title}</h1>
      </div>
      <div className="columns is-multiline is-centered">
        {items.map((data, idx) => {
          return (
            <div key={idx} className="column is-one-quarter">
              <div className="card-image">
                <figure className="image">
                  <GatsbyImage
                    image={getImage(data.image) || data.image}
                    alt={`Image for ${data.text}`}
                  />
                </figure>
              </div>
              <div className="card-content">
                <p className="content has-text-centered is-medium">
                  {data.text}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

CardList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default CardList;
